//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "App",

  data() {
    return {};
  },

  computed: {
    // if home route, hide all bars and panels
    isHomeRoute() {
      return this.$route.name === 'home';
    },
    leftPanel: {
      get() {
        // console.log('app: getLeftPanel');
        return this.$store.state.app.leftPanel;
      }
      ,
      set: function (value) {
        // console.log('app: setLeftPanel: ', value);
        this.$store.dispatch('app/updateLeftPanel', value);
      },
    },
    rightPanel: {
      get() {
        // console.log('app: getRightPanel');
        return this.$store.state.app.rightPanel;
      },
      set: function (value) {
        // console.log('app: setRightPanel: ', value);
        this.$store.dispatch('app/updateRightPanel', value);
      },
    },
    localLeftPanel() {
      if (this.$route.name === 'lmi-2015') {
        return this.$store.state.lmi2015.leftPanel;
      } else if (this.$route.name === 'lie-2020-1') {
        return this.$store.state.lie2020_1.leftPanel;
      } else if (this.$route.name === 'spatii-publice') {
        return this.$store.state.spatiiPublice.leftPanel;
      } else if (this.$route.name === 'infrastructura-sanatate') {
        return this.$store.state.infrastructuraSanatate.leftPanel;
      } else if (this.$route.name === 'toalete-publice') {
        return this.$store.state.toaletePublice.leftPanel;
      } else {
        return this.$store.state.reabilitareTermica.leftPanel;
      }
    },
  },

  methods: {
    toggleLeftPanel() {
      // update current tab leftPanel
      if (this.$route.name === 'lmi-2015') {
        this.$store.dispatch('lmi2015/updateLeftPanel', true);
      } else if (this.$route.name === 'lie-2020-1') {
        this.$store.dispatch('lie2020_1/updateLeftPanel', true);
      } else if (this.$route.name === 'spatii-publice') {
        this.$store.dispatch('spatiiPublice/updateLeftPanel', true);
      } else if (this.$route.name === 'infrastructura-sanatate') {
        this.$store.dispatch('infrastructuraSanatate/updateLeftPanel', true);
      } else if (this.$route.name === 'toalete-publice') {
        this.$store.dispatch('toaletePublice/updateLeftPanel', true);
      } else if (this.$route.name === 'reabilitare-termica') {
        this.$store.dispatch('reabilitareTermica/updateLeftPanel', true);
      }
      // update app leftPanel
      if (this.$q.platform.is.desktop) {
        this.$store.dispatch('app/toggleLeftPanel');
      } else {
        this.$store.dispatch('app/updateLeftPanel', true);
      }
    },

    toggleRightPanel() {
      // update current tab leftPanel
      if (this.$route.name === 'lmi-2015') {
        this.$store.dispatch('lmi2015/updateRightPanel', true);
      } else if (this.$route.name === 'lie-2020-1') {
        this.$store.dispatch('lie2020_1/updateRightPanel', true);
      } else if (this.$route.name === 'spatii-publice') {
        this.$store.dispatch('spatiiPublice/updateRightPanel', true);
      } else if (this.$route.name === 'infrastructura-sanatate') {
        this.$store.dispatch('infrastructuraSanatate/updateRightPanel', true);
      } else if (this.$route.name === 'toalete-publice') {
        this.$store.dispatch('toaletePublice/updateRightPanel', true);
      } else if (this.$route.name === 'reabilitare-termica') {
        this.$store.dispatch('reabilitareTermica/updateRightPanel', true);
      }
      // update app rightPanel
      this.$store.dispatch('app/toggleRightPanel');
    },
  },

  created: async function () {

    // get 'lmi2015' monuments list
    await this.$store.dispatch('lmi2015/loadAllData');

    // get 'lmi2015' monuments photos
    this.$store.dispatch('photos/getMonumentImages', this.$store.state.photos.monumentShown.nr);

    // load 'lie2020-1' data into store
    this.$store.dispatch('lie2020_1/loadAllData');

    // load 'spatii publice' data into store
    await this.$store.dispatch('spatiiPublice/loadAllData');

    // load 'infrastructura sanatate' data into store
    this.$store.dispatch('infrastructuraSanatate/loadAllData');

    // load 'toalete publice' data into store
    this.$store.dispatch('toaletePublice/loadAllData');

    // load 'reabilitare termica' data into store
    this.$store.dispatch('reabilitareTermica/loadAllData');

    // if platform is desktop, show left panel
    if (this.$q.platform.is.desktop) this.leftPanel = true;

  },
};
