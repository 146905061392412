import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './quasar';

// vue layers
// import VueLayers from 'vuelayers';
// import 'vuelayers/lib/style.css';
// Vue.use(VueLayers);


// vue leaflet
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import { Icon } from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import 'mapbox-gl/dist/mapbox-gl.css';

// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);

// this part resolve an issue where the markers would not appear
// delete Icon.Default.prototype._getIconUrl;

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// });
// vue leaflet

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
